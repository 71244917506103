import FormGroup from "@mui/material/FormGroup";
import { appThemeApiRef, useApi } from "@backstage/core-plugin-api";
import { useObservable } from "react-use";
import CustomizedSwitch from "./SwitchThemeStyles";

enum Theme {
  Dark = "vf-theme-dark",
  Light = "vf-theme-light",
}

export default function SwitchTheme() {
  const appThemeApi = useApi(appThemeApiRef);
  const activeThemeId = useObservable(
    appThemeApi.activeThemeId$(),
    appThemeApi.getActiveThemeId(),
  );

  const handleSetTheme = () => {
    appThemeApi.setActiveThemeId(
      activeThemeId === Theme.Dark ? Theme.Light : Theme.Dark,
    );
  };

  return (
    <FormGroup>
      <CustomizedSwitch
        sx={{ m: 1 }}
        checked={activeThemeId === Theme.Dark}
        onChange={handleSetTheme}
      />
    </FormGroup>
  );
}
