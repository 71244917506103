import { ConfigApi } from "@backstage/core-plugin-api";
import { ScmAuthApi } from "@backstage/integration-react";
import { RestEndpointMethodTypes } from "@octokit/rest";
import {
  GithubFirstCommitDate,
  GithubRepositoryData,
  SearchPullRequestsResponseData,
} from "../types";

export type GetContentResponse = {
  content: string;
  media: Record<string, string>;
  links: Record<string, string>;
};

export type GetContentProps = {
  baseUrl?: string;
  owner: string;
  repo: string;
  branch?: string;
  path: string;
};

export interface GithubApi {
  getContent(props: GetContentProps): Promise<GetContentResponse>;
}

export type Integrations = {
  host: string;
  [key: string]: string | number | boolean;
};

export type Options = {
  configApi: ConfigApi;
  scmAuthApi: ScmAuthApi;
};

export interface Workflows {
  workflow: {
    id: number;
    name: string;
    state: string;
    url: string;
    path: string;
    createdAt: string;
    updatedAt: string;
  };
  latestRun: {
    id?: number;
    status?: string;
    conclusion?: string;
  };
  parameters: WorkflowDispatchParameters[];
}
export enum StatusWorkflowEnum {
  completed = "completed",
  failure = "failure",
  queued = "queued",
  aborted = "aborted",
  inProgress = "in_progress",
  skipped = "skipped",
  canceled = "cancelled",
  timeOut = "timed_out",
  default = "default",
  actionRequired = "action_required",
  neutral = "neutral",
  stale = "stale",
  success = "success",
  requested = "requested",
  waiting = "waiting",
  pending = "pending",
}

export interface GithubFileResponse {
  type: "dir" | "file" | "submodule" | "symlink";
  encoding: string;
  size: number;
  name: string;
  path: string;
  content: string;
  sha: string;
  url: string;
  git_url: string;
  html_url: string;
  download_url: string;
  _links: {
    git: string;
    self: string;
    html: string;
  };
}
export type GithubPullRequestsApi = {
  listPullRequests: ({
    search,
    token,
    owner,
    repo,
    pageSize,
    page,
    branch,
    baseUrl,
  }: {
    search: string;
    token: string;
    owner: string;
    repo: string;
    pageSize?: number;
    page?: number;
    branch?: string;
    baseUrl: string | undefined;
  }) => Promise<{
    pullRequestsData: SearchPullRequestsResponseData;
  }>;

  getRepositoryData: ({
    baseUrl,
    token,
    url,
  }: {
    baseUrl: string | undefined;
    token: string;
    url: string;
  }) => Promise<GithubRepositoryData>;

  getCommitDetailsData({
    baseUrl,
    token,
    owner,
    repo,
    number,
  }: {
    baseUrl: string | undefined;
    token: string;
    owner: string;
    repo: string;
    number: number;
  }): Promise<GithubFirstCommitDate>;
};

export interface GithubReadmeApi {
  getContent(props: GetContentProps): Promise<GetContentResponse>;
}

export interface GithubWorkflowsApi {
  listWorkflows(
    hostname: string,
    githubRepoSlug: string,
    branch: string,
    filter?: string[],
  ): Promise<Workflows[]>;

  listBranchesFromRepo(
    hostname: string,
    githubRepoSlug: string,
  ): Promise<
    RestEndpointMethodTypes["repos"]["listBranches"]["response"]["data"]
  >;

  getBranchDefaultFromRepo(
    hostname: string,
    githubRepoSlug: string,
  ): Promise<
    RestEndpointMethodTypes["repos"]["get"]["response"]["data"]["default_branch"]
  >;

  startWorkflowRun(
    hostname: string,
    githubRepoSlug: string,
    workflowId: number,
    branch: string,
    inputs?: { [key: string]: unknown },
  ): Promise<
    RestEndpointMethodTypes["actions"]["createWorkflowDispatch"]["response"]["status"]
  >;

  stopWorkflowRun(
    hostname: string,
    githubRepoSlug: string,
    runId: number,
  ): Promise<
    RestEndpointMethodTypes["actions"]["cancelWorkflowRun"]["response"]["status"]
  >;

  listJobsForWorkflowRun(
    hostname: string,
    githubRepoSlug: string,
    id: number,
    pageSize?: number,
    page?: number,
  ): Promise<
    RestEndpointMethodTypes["actions"]["listJobsForWorkflowRun"]["response"]["data"]
  >;

  getWorkflowRunById(
    hostname: string,
    githubRepoSlug: string,
    runId: number,
  ): Promise<
    RestEndpointMethodTypes["actions"]["getWorkflowRun"]["response"]["data"]
  >;

  downloadJobLogsForWorkflowRun(
    hostname: string,
    githubRepoSlug: string,
    jobId: number,
  ): Promise<
    RestEndpointMethodTypes["actions"]["downloadJobLogsForWorkflowRun"]["response"]["data"]
  >;

  getFileContentFromPath(
    hostname: string,
    githubRepoSlug: string,
    filePath: string,
    branch: string,
  ): Promise<any>;

  getEnvironmentsList(
    hostname: string,
    githubRepoSlug: string,
  ): Promise<
    RestEndpointMethodTypes["repos"]["getAllEnvironments"]["response"]["data"]
  >;
}

export interface WorkflowAnnotation {
  workflow: string;
  label?: string;
  tooltip?: string;
}

export interface Workflow {
  id: number;
  nodeId: string;
  name: string;
  path: string;
  state: string;
  createdAt: string;
  updatedAt: string;
  url: string;
  html_url: string;
  badgeUrl: string;
}

export interface WorkflowResponseFromApi {
  total_count: number;
  workflows: Workflow[];
}

export interface WorkflowRun {
  id: number;
  name: string;
  head_branch: string;
  event: string;
  status: string;
  conclusion: string;
  run_started_at: string;
  created_at: string;
  updated_at: string;
  actor?: Actor;
  path?: string;
  artifacts_url?: string;
  head_sha?: string;
  repository?: Repository;
}

export type Actor = {
  id: number;
  login: string;
  avatar_url: string;
};

export type Repository = {
  id: number;
  name: string;
  full_name: string;
};

export interface WorkflowRunsResponseFromApi {
  total_count: number;
  workflow_runs: WorkflowRun[];
}

export interface Branch {
  name: string;
  commit?: Commit;
  protected: boolean;
  protection: Protection;
  protection_url: string;
}

export interface Protection {
  required_status_checks: {
    enforcement_level: string;
    contexts: string[];
  };
}

export interface Commit {
  sha: string;
  url: string;
}

export interface WorkflowResultsProps {
  id?: number;
  name?: string;
  lastRunId?: number;
  status?: string;
  conclusion?: string;
  source?: string;
  path?: string;
  parameters?: WorkflowDispatchParameters[] | [];
}

export interface WorkflowDispatchParameters {
  name: string;
  description: string;
  required: boolean;
  type: boolean | number | "choice" | string;
  default: string | boolean;
  options?: Array<any>;
}

export interface Step {
  name: string;
  status: string;
  conclusion?: string;
  number: number;
  started_at?: string;
  completed_at?: string;
}

export interface Job {
  html_url?: string;
  status: string;
  conclusion?: string;
  started_at: string;
  completed_at?: string;
  id: number;
  name: string;
  steps?: Step[];
}

export interface Jobs {
  total_count: number;
  jobs: Job[];
}
